import React, {Component} from "react";
import List from "../../components/List/List";
import {ClientUserFilter} from "../../models/ClientUserFilter";

class ClientUser extends Component {
    constructor() {
        super();
        this.addPath = '/admin/client-users/add';
        this.editPath = '/admin/client-users';
    }

    render() {
        return (
            <List
                name='ClientUsers'
                editPath={this.editPath}
                removeUrl='/admin/client-users'
                listApiUrl='/admin/client-users'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Email', row: 'email'},
                    {col: 'Name', row: 'name'},
                    {col: 'Last Name', row: 'last_name'},
                    {col: 'Locale', row: 'locale'},
                    {col: 'Timezone', row: 'timezone'},
                    {col: 'Created At', row: 'created_at'},
                    {col: 'Client Version', row: 'client_version'},
                    {col: 'Source Product', row: 'source_product'}
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={ClientUserFilter}
                childrenName='i'
                childrenPath='/info'
            >
            </List>

        );
    }
}

export default ClientUser;
