import {buildSelector} from "../utils/Utils";

const requestNormalizeCourse = (data) => {
    return data.map((value) => {
        return parseInt(value.value);
    })
}

const responseNormalizeCourse = (data) => {
    return data.map((value) => {
        return {value: parseInt(value.id), label: `[${value.id}] ${value.name}`}
    })
}

export const ClientUser = (courses) => {
    return {
        id: {
            value: null,
            hidden: true
        },
        email: {
            value: '',
            isDisabled: true
        },
        courses: buildSelector(
            courses,
            'multiSelect',
            requestNormalizeCourse,
            responseNormalizeCourse
        )
    };
}