const CourseModel = {
    id: {
        value: null,
        hidden: true
    },
    name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    short_name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    total_duration: {
        type: 'input',
        value: 0,
        dataType: "integer"
    },
    description: {
        type: 'html_editor',
        value: '',
        onChangeEvent: 'handleHTMLEditor'
    },
    video_preview: {
        type: 'input',
        validationRules: [{'regex': "^https://.*"}],
        value: '',
    },
    preview_category_link: {
        type: 'input',
        validationRules: [{'regex': "^https://.*"}],
        value: '',
    },
    position: {
        type: 'input',
        value: 0,
        dataType: "integer"
    },
    preview_link: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
        value: '',
    },
    identifier: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    branch_name: {
        type: 'input',
        value: '',
    },
    test_name: {
        type: 'input',
        value: '',
    },
};


CourseModel['published'] = {
    inputType: 'checkbox',
    value: false,
    requestNormalizer: (data) => {
        return data === "true" || data === true
    }
}
CourseModel['is_main'] = {
    inputType: 'checkbox',
    value: false,
    requestNormalizer: (data) => {
        return data === "true" || data === true
    }
}
CourseModel['category'] = {
    type: 'select',
    onChangeEvent: 'handleSelect',
    selectOptions: [
        {value: 'NUTRITION', label: 'NUTRITION'},
        {value: 'FITNESS', label: 'FITNESS'},
        {value: 'WELLBEING', label: 'WELLBEING'},
        {value: 'BEAUTY', label: 'BEAUTY'}
    ],
    value: {value: "text", label: "text"},
    requestNormalizer: (data) => {
        return data.value
    }
}
CourseModel['content_type'] = {
    type: 'select',
    onChangeEvent: 'handleSelect',
    selectOptions: [
        {value: 'VIDEO', label: 'VIDEO'},
        {value: 'TEXT', label: 'TEXT'},
        {value: 'AUDIO', label: 'AUDIO'}
    ],
    value: {value: "text", label: "text"},
    requestNormalizer: (data) => {
        return data.value
    }
}
CourseModel['is_public'] = {
    inputType: 'checkbox',
    value: false,
    requestNormalizer: (data) => {
        return data === "true" || data === true
    }
}

export default CourseModel;