const LectureModel = {
    id: {
        value: null,
        hidden: true
    },
    cropped_photo_link: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://*"}],
        value: '',
    },
    photo_link: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://*"}],
        value: '',
    }
};

export default LectureModel;