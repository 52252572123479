import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {API} from "aws-amplify";
import {contentfulLink} from "variables/Variables"


class SyncButton extends Component {
    onSyncData() {
        API.get('admin', contentfulLink.admin_sync_contentful_data).then(data => {
            const response_data = {...data?.items}
            console.log(response_data)
        }).catch(error => {
            console.log("Error " + error);
        })
    }

    render() {
        if (this.props.syncPath) {
            return <Button
                onClick={this.onSyncData}
                className="btn btn-success"
            >
                Sync
            </Button>
        }

        return ''
    }
}

export default SyncButton;
