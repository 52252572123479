import React, {Component} from "react";
import {Nav, NavItem} from "react-bootstrap";
import {Auth} from "aws-amplify";

class AdminNavbarLinks extends Component {
    constructor() {
        super();
        this.state = {
            email: 'user'
        }
        Auth.currentSession().then(res => {
            this.setState({email: res.getIdToken().decodePayload().email})
        })
    }

    handleLogout = () => {
        Auth.signOut();
    };

    render() {
        return (
            <div>
                <Nav pullRight>
                    <NavItem>
                        {this.state.email}
                    </NavItem>
                    <NavItem onClick={this.handleLogout}>
                        Log out
                    </NavItem>
                </Nav>
            </div>
        );
    }
}

export default AdminNavbarLinks;
