const LectureModel = {
    id: {
        value: null,
        hidden: true
    },
    name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    short_description: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    description: {
        type: 'html_editor',
        value: '',
        onChangeEvent: 'handleHTMLEditor'
    },
    cropped_photo_link: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://*"}],
        value: '',
    },
    photo_link: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://*"}],
        value: '',
    }
};

export default LectureModel;