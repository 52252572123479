import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Row, Checkbox} from "react-bootstrap";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import {toTitleCase} from "../../utils/Utils";
import TableCollection from "./TableCollection";
import Collection from "./Collection";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-image-resize';
import UploadAdapterPlugin from "./UploadAdapterPlugin";
import CONFIG from "../../config"
import {CheckboxRow} from "aws-amplify-react";

const TYPES = {
    FormControl: 'input',
    Select: 'select',
    MultiSelect: 'multiSelect',
    Collection: 'collection',
    TableCollection: 'table_collection',
    HTMLEditor: 'html_editor',
};

const animatedComponents = makeAnimated();

export class FormRow extends Component {
    render() {
        var label;
        var field;
        var validator;

        if (this.props.label !== false) {
            label = <ControlLabel>{toTitleCase(this.props.name)}</ControlLabel>
        }

        if (this.props.type === TYPES.Select || this.props.type === TYPES.MultiSelect) {
            field = <Select
                placeholder={toTitleCase(this.props.placeholder || this.props.name)}
                name={this.props.name}
                value={this.props.value}
                isLoading={this.props.selectOptions.length === 0}
                components={animatedComponents}
                onChange={this.props.onChangeEvent}
                options={this.props.selectOptions}
                isMulti={this.props.type === TYPES.MultiSelect}
                closeMenuOnSelect={this.props.type !== TYPES.MultiSelect}
                isDisabled={this.props.isDisabled}
            />
        } else if (this.props.type === TYPES.TableCollection) {
            field = <TableCollection
                name={this.props.name}
                prototype={this.props.prototype}
                value={this.props.value}
                onChange={this.props.onChangeEvent}
                validator={this.props.validator}
            />
        } else if (this.props.type === TYPES.Collection) {
            field = <Collection
                name={this.props.name}
                prototype={this.props.prototype}
                value={this.props.value}
                onChange={this.props.onChangeEvent}
                validator={this.props.validator}
            />
        } else if (this.props.type === TYPES.HTMLEditor) {
            field =  <CKEditor
                editor={ ClassicEditor }
                onChange={(data, editor) => {
                    this.props.onChangeEvent(this.props.name, editor.getData())
                }}
                data={this.props.value}
                disabled={this.props.isDisabled}
                config={{
                    extraPlugins: [ UploadAdapterPlugin ],
                    uploadUrl: (CONFIG.API_URL + '/admin/upload-image'),
                    xApiKey: CONFIG.API_KEY
                }}
            />
        } else {
            if (this.props.inputType === "checkbox") {
                field = <CheckboxRow
                    type={"checkbox"}
                    name={this.props.name}
                    checked={this.props.value === 'true' || this.props.value === true}
                    onChange={event => {
                        event.target.value = event.target.checked
                        this.props.onChangeEvent(event)
                    }}
                />
            } else {
                field = <FormControl
                    type={this.props.inputType || "text"}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChangeEvent}
                    placeholder={toTitleCase(this.props.placeholder || this.props.name)}
                    disabled={this.props.isDisabled}
                />
            }
        }
        if (this.props.validator !== undefined && this.props.validationRules !== '') {
            validator = this.props.validator.message(this.props.name, this.props.value, this.props.validationRules)
        }

        return (
            <Row>
                <Col md={this.props.md || 12}>
                    <FormGroup>
                        {label}
                        {field}
                        {validator}
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default FormRow;
