import React, {Component} from "react";
import List from "../../components/List/List";
import {contentfulLink} from "variables/Variables";

class ContentfulArticles extends Component {
    constructor() {
        super();
        this.syncPath = contentfulLink.admin_sync_contentful_data;
    }

    render() {
        return (
            <List
                name='Contentful'
                syncPath={this.syncPath}
                editPath=''
                removeUrl='/admin/client-user'
                listApiUrl='/admin/contentful/articles'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'contentful_id', row: 'contentful_id'},
                    {col: 'category', row: 'category'},
                    {col: 'content_type', row: 'content_type'},
                    {col: 'title', row: 'title'},
                    {col: 'author', row: 'author'},
                    {col: 'duration', row: 'duration'},
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
            >
            </List>

        );
    }
}

export default ContentfulArticles;
