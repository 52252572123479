import React, {Component} from "react";
import List from "../../components/List/List";
import {NavLink} from "react-router-dom";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";

const DEFAULT_LANGUAGE = 'en';

class Course extends Component {
        static contextType = ApiConstantsContext;

    constructor(props) {
        super();
        this.addPath = '/admin/courses/add';
        this.editPath = '/admin/courses/edit';
    }

    getTranslation(translations, language) {
        for (let translation of translations) {
            if (translation.language === language) {
                return translation;
            }
        }
    }

    render() {
        let constants = this.context.constants,
            model = [
                {
                col: 'Id',
                row: 'id',
                normalizer: (course) => {
                    return course.id;
                }
            },{
                col: 'Name',
                row: 'name',
                normalizer: (course) => {
                    let translation = this.getTranslation(course.translations, DEFAULT_LANGUAGE);
                    return translation ? translation.name : `Course ${course.id || ''}`;
                }
            },
                {
                        col: 'Preview', row: 'preview_link', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview_link}
                                        width="150"/>
                        }
                    },],
            languages = Object.values(constants.language === undefined ? {} : constants.language.items || {})
                .map((language) => { return language.key });

        languages.forEach(language => {
            model.push({
                col: '',
                row: language,
                normalizer: (data) => {
                    let translation = this.getTranslation(data.translations, language);

                    if (translation) {
                        return <NavLink to={`/admin/courses/translations/${translation.id}`}>
                            {language}
                        </NavLink>
                    }

                    return <NavLink to={`/admin/courses/${data.id}/translations/${language}`}>{language}</NavLink>
                }
            })
        })

        return (
            <List
                name="Courses"
                addPath={this.addPath}
                editPath={this.editPath}
                childrenName="Module"
                childrenPath="/modules"
                removeUrl="/admin/courses"
                listApiUrl='/admin/courses'
                model={model}
                handleClick={this.props.handleClick}
            >
            </List>

        );
    }
}

export default Course;
