import ModuleAdd from "./ModuleAdd";
import ModuleEditModel from "../../../models/ModuleEdit";


export default class ModuleEdit extends ModuleAdd {
    getEntity = () => {
        return ModuleEditModel;
    }
}


