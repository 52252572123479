import Form from "../../../components/Form/Form";
import React, {Component} from "react";
import TranslationModel from "../../../models/LectureTranslation";


export default class TranslationAdd extends Component {
    constructor(props) {
        super(props);
        this.name = "Translation"
        this.url = "/admin/lecturers"
        this.redirectUrl = '/admin/lecturers'
    }

    isEdit = () => {
        return Boolean(this.props.match.params.id)
    }

    getTitle = () => {
        return this.isEdit()
            ? `Edit Lecture ${this.name} ${this.props.match.params.hasOwnProperty('translation') ? 'Translation' : ''}`
            : `Add ${this.name}`
    }

    onSuccess = () => {
        this.props.handleClick(
            this.isEdit() ?
                `${this.name} ${this.props.match.params.id} successfully updated` :
                `${this.name} successfully created`,
            "success", "tr"
        );
        this.props.history.push(this.redirectUrl || this.url);
    };

    getEntityUrl = () => {
        return this.props.match.params.hasOwnProperty('language')
            ? `/admin/lecturers/${this.props.match.params.lecture_id}/translations/${this.props.match.params.language}`
            : `/admin/lecturers/translations/${this.props.match.params.id}`;
    }

    render() {
        return <Form
            entity={TranslationModel}
            title={this.getTitle()}
            url={this.getEntityUrl()}
            getEntityUrl={this.getEntityUrl()}
            actionUrl={this.getEntityUrl()}
            onSuccess={this.onSuccess}
            handleClick={this.props.handleClick}
            id={this.props.match.params.id}
        />
    }
}