import CourseAdd from "./CourseAdd";
import CourseEditModel from "../../models/CourseEdit";
import React from "react";
import {API} from "aws-amplify";

export default class CourseEdit extends CourseAdd {
    constructor() {
        super();
        this.state = {
            lectures: {},
        }
    }
    componentDidMount() {
        this.getLectures().then((lectures) => {
            const course_id = this.props.match.params.id
            API.get('admin', '/admin/courses/' + course_id).then((resp) => {
                for(let item in lectures.lecture_id.selectOptions) {
                    if (lectures.lecture_id.selectOptions[item].value === resp.lecture_id) {
                        lectures.lecture_id["placeholder"] = lectures.lecture_id.selectOptions[item].label
                    }
                }
                this.setState({
                    lectures: lectures,
                })
            })
        })
    }

    getEntity = () => {
        return {...CourseEditModel, ...this.state.lectures};
    }
}

