const ModuleEditModel = {
    id: {
        value: null,
        hidden: true
    },
    preview_link: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://.*"}],
        value: '',
    },
    position: {
        type: 'input',
        value: 0,
        validationRules: 'required',
        dataType: "integer"
    },
};


export default ModuleEditModel;