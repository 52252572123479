import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import LectureModel from "../../models/Lecture";
import React from "react";


export default class LectureAdd extends BaseAddEditComponent {
    constructor() {
        super();
        this.name = "Lecture"
        this.url = "/admin/lecturers"
        this.redirectUrl = '/admin/lecturers'
    }

    getEntity = () => {
        return LectureModel;
    }
}