import TranslationModel from "./Translation";

let LessonModel = TranslationModel;

LessonModel['type'] = {
    type: 'select',
    onChangeEvent: 'handleSelect',
    selectOptions: [
        {value: 'video', label: 'video'},
        {value: 'text', label: 'text'},
        {value: 'practice', label: 'practice'},
        {value: 'handouts', label: 'handouts'}
    ],
    value: {value: "text", label: "text"},
    requestNormalizer: (data) => {
        return data.value
    }
}

LessonModel['position'] = {
    type: 'input',
    value: 0,
    dataType: "integer"
}
LessonModel['duration'] = {
    type: 'input',
    value: 0,
    dataType: "integer"
}


export default LessonModel;