import ClientUser from "./controllers/Users/ClientUsers";
import Lectures from "./controllers/Lecturers/Lectures";
import LectureAdd from "./controllers/Lecturers/LectureAdd";
import LectureEdit from "./controllers/Lecturers/LectureEdit";
import Course from "./controllers/Courses/Course";
import CourseAdd from "./controllers/Courses/CourseAdd";
import CourseEdit from "./controllers/Courses/CourseEdit";
import Lesson from "./controllers/Courses/Lessons/Lesson";
import LessonAdd from "./controllers/Courses/Lessons/LessonAdd";
import LessonEdit from "./controllers/Courses/Lessons/LessonEdit";
import ClientUserEdit from "./controllers/Users/ClientUserEdit";
import ClientUserInfo from "./controllers/Users/ClientUserInfo";
import TranslationEdit from "./controllers/Courses/Lessons/Translations/TranslationEdit";
import TranslationAdd from "./controllers/Courses/Lessons/Translations/TranslationAdd";
import LectureTranslationEdit from "./controllers/Lecturers/Translations/TranslationEdit";
import LectureTranslationAdd from "./controllers/Lecturers/Translations/TranslationAdd";
import CourseTranslationAdd from "./controllers/Courses/Translations/TranslationAdd";
import CourseTranslationEdit from "./controllers/Courses/Translations/TranslationEdit";
import Module from "./controllers/Courses/Modules/Module";
import ModuleAdd from "./controllers/Courses/Modules/ModuleAdd";
import ModuleEdit from "./controllers/Courses/Modules/ModuleEdit";
import ModuleTranslationAdd from "./controllers/Courses/Modules/Translations/TranslationAdd";
import ModuleTranslationEdit from "./controllers/Courses/Modules/Translations/TranslationEdit";
import ContentfulLandings from "./controllers/Contentful/ContentfulLandings";
import ContentfulArticles from "./controllers/Contentful/ContentfulArticles";


const dashboardRoutes = [
    {
        exact: true,
        path: "/courses",
        name: "Courses",
        icon: "pe-7s-news-paper",
        component: Course,
        layout: "/admin"
    },
    {
        path: "/courses/add",
        name: "Course Add",
        icon: "pe-7s-news-paper",
        component: CourseAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/courses/edit/:id",
        name: "Course Edit",
        icon: "pe-7s-news-paper",
        component: CourseEdit,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/courses/translations/:id",
        name: "Course Translation Add",
        icon: "pe-7s-news-paper",
        component: CourseTranslationAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/courses/:course_id/translations/:language",
        name: "Course Translation Edit",
        icon: "pe-7s-news-paper",
        component: CourseTranslationEdit,
        layout: "/admin",
        invisible: true
    },
    {
        exact: true,
        path: "/courses/:course_id/modules",
        name: "Modules",
        icon: "pe-7s-news-paper",
        component: Module,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/courses/:course_id/modules/add",
        name: "Module Add",
        icon: "pe-7s-news-paper",
        component: ModuleAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/courses/:course_id/modules/edit/:id",
        name: "Module Edit",
        icon: "pe-7s-news-paper",
        component: ModuleEdit,
        layout: "/admin",
        invisible: true
    },
    {
        exact: true,
        path: "/courses/:course_id/modules/:module_id/lessons",
        name: "Lessons",
        icon: "pe-7s-news-paper",
        component: Lesson,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/modules/:module_id/lessons/add",
        name: "Lesson Add",
        icon: "pe-7s-news-paper",
        component: LessonAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/modules/:module_id/lessons/edit/:id",
        name: "Lesson edit",
        icon: "pe-7s-news-paper",
        component: LessonEdit,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/modules/translations/:id",
        name: "Module Translation Add",
        icon: "pe-7s-news-paper",
        component: ModuleTranslationAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/modules/:module_id/translations/:language",
        name: "Course Translation Edit",
        icon: "pe-7s-news-paper",
        component: ModuleTranslationEdit,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/lessons/translations/:id",
        name: "Lesson Translation Add",
        icon: "pe-7s-news-paper",
        component: TranslationAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/lessons/:lesson_id/translations/:language",
        name: "Lesson Translation Edit",
        icon: "pe-7s-news-paper",
        component: TranslationEdit,
        layout: "/admin",
        invisible: true
    },
    {
        exact: true,
        path: "/client-users",
        name: "Clients",
        icon: "pe-7s-user",
        component: ClientUser,
        layout: "/admin"
    },
    {
        path: "/client-users/:id/info",
        name: "Client info",
        component: ClientUserInfo,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/client-users/:id",
        name: "Client edit",
        icon: "pe-7s-user",
        component: ClientUserEdit,
        layout: "/admin",
        invisible: true
    },
    {
        exact: true,
        path: "/lecturers",
        name: "Lecturers",
        icon: "pe-7s-user",
        component: Lectures,
        layout: "/admin"
    },
    {
        path: "/lecturers/add",
        name: "Lecture Add",
        icon: "pe-7s-news-paper",
        component: LectureAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/lecturers/edit/:id",
        name: "Lecture Edit",
        icon: "pe-7s-news-paper",
        component: LectureEdit,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/lecturers/translations/:id",
        name: "Lecture Translation Add",
        icon: "pe-7s-news-paper",
        component: LectureTranslationAdd,
        layout: "/admin",
        invisible: true
    },
    {
        path: "/lecturers/:lecture_id/translations/:language",
        name: "Lesson Translation Edit",
        icon: "pe-7s-news-paper",
        component: LectureTranslationEdit,
        layout: "/admin",
        invisible: true
    },
    {
        exact: true,
        path: "/contentful_landing",
        name: "Contentful Landing",
        icon: "pe-7s-airplay",
        component: ContentfulLandings,
        layout: "/admin"
    },
    {
        exact: true,
        path: "/contentful_article",
        name: "Contentful Article",
        icon: "pe-7s-airplay",
        component: ContentfulArticles,
        layout: "/admin"
    },
];

export default dashboardRoutes;
