import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import {style} from "variables/Variables.jsx";

import routes from "routes.js";

import image from "assets/img/sidebar-3.jpg";
import {Auth} from 'aws-amplify';
import Loader from "../components/Loader/Loader";
import {ApiConstantsContextProvider} from "../variables/ApiConstantsContext";
import defaults from "../defaults";

class Admin extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = React.createRef();

        this.state = {
            color: "black",
            hasImage: true,
            fixedClasses: "dropdown show-dropdown open",
            isAuthenticated: false,
            isAuthenticating: true,
        };

        this.checkAuth()
    }

    _notificationSystemInstance() {
        return this._notificationSystem.current;
    }

    handleNotificationClick = (message, level, position) => {
        this._notificationSystemInstance().addNotification({
            title: <span data-notify="icon" className="pe-7s-gift"/>,
            message: (
                <div>
                    {message}
                </div>
            ),
            level: level,
            position: position,
            autoDismiss: 15
        });
    };
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        exact={prop.exact || false}
                        path={prop.layout + prop.path}
                        render={props => (
                            <prop.component
                                {...props}
                                handleClick={this.handleNotificationClick}
                            />
                        )}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
            if (
                this.props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };
    handleImageClick = image => {
        this.setState({image: image});
    };
    handleColorClick = color => {
        this.setState({color: color});
    };
    handleHasImage = hasImage => {
        this.setState({hasImage: hasImage});
    };
    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
            this.setState({fixedClasses: "dropdown show-dropdown open"});
        } else {
            this.setState({fixedClasses: "dropdown"});
        }
    };

    componentDidMount() {
        this.setState({_notificationSystem: this.refs.notificationSystem});
    }

    checkAuth = async () => {
        await Auth.currentAuthenticatedUser().then(() => {
            this.setState({
                isAuthenticated: true,
                isAuthenticating: false
            })
        }).catch(e => {
            Auth.federatedSignIn()
        });
    }

    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
        if (e.history.action === "PUSH") {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }

    render() {
        document.title = `${this.getBrandText()} | ${defaults.app.name}`
        return (
            <ApiConstantsContextProvider>
                <Loader isLoading={this.state.isAuthenticating}>
                    <div className="wrapper">
                        <NotificationSystem ref={this._notificationSystem}
                                            style={style}/>
                        <Sidebar {...this.props} routes={routes}
                                 image={this.state.image}
                                 color={this.state.color}
                                 hasImage={this.state.hasImage}/>
                        <div id="main-panel" className="main-panel"
                             ref="mainPanel">
                            <AdminNavbar
                                {...this.props}
                                brandText={this.getBrandText(this.props.location.pathname)}
                            />
                            <Switch>{this.getRoutes(routes)}</Switch>
                            <Footer/>
                        </div>
                    </div>
                </Loader>
            </ApiConstantsContextProvider>
        );
    }
}

export default Admin;
