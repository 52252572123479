import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";
import Card from "../../components/Card/Card";
import Loader from "../../components/Loader/Loader";
import {API} from "aws-amplify";

export default class ClientUserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                id: props.match.params.id,
                course: []
            },
            isLoading: true,
        }
    }

    componentDidMount() {
        API.get('admin', `/admin/client-users/${this.state.user.id}`)
            .then(data => {
                this.setState({
                    user: data,
                    isLoading: false,

                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                })
                console.error(error)
            })
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Info for user ${this.state.user.email || ''}`}
                            />
                            <Card
                                ctTableResponsive
                                content={
                                    <Loader isLoading={this.state.isLoading}>
                                        <div>
                                            <h4>User info:</h4>
                                            <Table striped hover bordered>
                                                <tbody>
                                                    <tr><th>email</th><td>{this.state.user.email || '-'}</td></tr>
                                                    <tr><th>id</th><td>{this.state.user.id || '-'}</td></tr>
                                                    <tr><th>is_paid</th><td>{this.state.user.is_paid ? 'yes' : 'no'}</td></tr>
                                                    <tr><th>is_invoice_email_sent</th><td>{this.state.user.is_email_sent ? 'yes' : 'no'}</td></tr>
                                                    <tr><th>is_password_set</th><td>{this.state.user.is_password_set ? 'yes' : 'no'}</td></tr>
                                                    <tr><th>name</th><td>{this.state.user.name || '-'}</td></tr>
                                                    <tr><th>last name</th><td>{this.state.user.last_name || '-'}</td></tr>
                                                    <tr><th>created_at</th><td>{this.state.user.created_at || '-'}</td></tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        {this.state.user.courses && this.state.user.courses.length !== 0 ?
                                            (
                                                <div>
                                                    <h4>Courses and pyments:</h4>
                                                    <Table striped hover bordered>
                                                        <thead>
                                                            <tr>
                                                            <th rowSpan={2} className="col-md-2">id</th>
                                                            <th rowSpan={2}>name</th>
                                                            <th colSpan={6}> payment</th>
                                                        </tr>
                                                            <tr>
                                                            <th>amount</th>
                                                            <th>external id</th>
                                                            <th>is invoice sent</th>
                                                            <th>payment type</th>
                                                            <th>status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            this.state.user.courses.map((course, index) => {
                                                                let payment = course.payment || {}
                                                                return (<tr key={index}>
                                                                    <td>{course.id || '-'}</td>
                                                                    <td>{course.name || '-'}</td>
                                                                    <td>{payment.amount ? (payment.amount / 100).toFixed(2) : '-'}</td>
                                                                    <td>{payment.external_id || '-'}</td>
                                                                    <td>{payment.is_invoice_sent ? 'yes' : 'no'}</td>
                                                                    <td>{payment.payment_type || '-'}</td>
                                                                    <td>{payment.status || '-'}</td>
                                                                </tr>)
                                                            })
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            ):
                                            <p>No user payment info</p>
                                        }
                                    </Loader>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}
