const LessonEditModel = {
    id: {
        value: null,
        hidden: true
    },
    type: {
        type: 'select',
        onChangeEvent: 'handleSelect',
        selectOptions: [
            {value: 'video', label: 'video'},
            {value: 'text', label: 'text'},
            {value: 'practice', label: 'practice'},
            {value: 'handouts', label: 'handouts'}
        ],
        value: {value: "text", label: "text"},
        requestNormalizer: (data) => {
            return data.value
        },
        responseNormalizer: (data) => {
            return {value: data, label: data}
        },
    },
    position: {
        type: 'input',
        value: 0,
        validationRules: 'required',
        dataType: "integer"
    },
    duration: {
        type: 'input',
        value: 0,
        validationRules: 'required',
        dataType: "integer"
    },
};

export default LessonEditModel;