import React, {Component} from "react";
import List from "../../components/List/List";
import {NavLink} from "react-router-dom";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";


const DEFAULT_LANGUAGE = 'en';

class Lectures extends Component {
    static contextType = ApiConstantsContext;

    constructor() {
        super();
        this.addPath = '/admin/lecturers/add';
        this.editPath = '/admin/lecturers/edit';
    }

    getTranslation(translations, language) {
        for (let translation of translations) {
            if (translation.language === language) {
                return translation;
            }
        }
    }

    render() {
        let constants = this.context.constants,
            model = [{
                col: 'Name',
                row: 'name',
                normalizer: (lecture) => {
                    let translation = this.getTranslation(lecture.translations, DEFAULT_LANGUAGE);
                    return translation ? translation.name : `Lesson ${lecture.id || ''}`;
                }
            },
                {
                        col: 'Preview', row: 'cropped_photo_link', normalizer: (data) => {
                            return <img alt={data.name} src={data.cropped_photo_link}
                                        width="150"/>
                        }
                    },],
            languages = Object.values(constants.language === undefined ? {} : constants.language.items || {})
                .map((language) => { return language.key });

        languages.forEach(language => {
            model.push({
                col: '',
                row: language,
                normalizer: (data) => {
                    let translation = this.getTranslation(data.translations, language);

                    if (translation) {
                        return <NavLink to={`/admin/lecturers/translations/${translation.id}`}>
                            {language}
                        </NavLink>
                    }

                     return <NavLink to={`/admin/lecturers/${data.id}/translations/${language}`}>{language}</NavLink>
                }
            })
        })

        return (
            <List
                name="Lecture"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl='/admin/lecturers'
                listApiUrl='/admin/lecturers'
                model={model}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
            >
            </List>

        );
    }
}

export default Lectures;
