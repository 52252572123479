const ModuleModel = {
    id: {
        value: null,
        hidden: true
    },
    name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    short_name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    description: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    preview_link: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://.*"}],
        value: '',
    },
    position: {
        type: 'input',
        value: 0,
        dataType: "integer"
    },
};


export default ModuleModel;