import ModuleModel from "../../../models/Module";
import Form from "../../../components/Form/Form";
import React, {Component} from "react";


export default class ModuleAdd extends Component {
    constructor(props) {
        super(props);
        this.name = "Module"
    }

    isEdit = () => {
        return Boolean(this.props.match.params.id)
    }

    getTitle = () => {
        return this.isEdit()
            ? `Edit ${this.name} ${this.props.match.params.hasOwnProperty('translation') ? 'Translation' : ''}`
            : `Add ${this.name}`
    }

    onSuccess = () => {
        this.props.handleClick(
            this.isEdit() ?
                `${this.name} ${this.props.match.params.id} successfully updated` :
                `${this.name} successfully created`,
            "success", "tr"
        );
        this.props.history.push(this.redirectUrl || this.url);
    };

    getEntityUrl = () => {
        let courseId = this.props.match.params.course_id,
            translation = this.props.match.params.hasOwnProperty('translation') ? this.props.match.params.translation : null,
            moduleId = this.props.match.params.hasOwnProperty('id') ? this.props.match.params.id : null;

        return this.isEdit()
            ? '/admin/modules/' + moduleId + (translation ? '/' + translation : '')
            : '/admin/courses/' + courseId + '/modules'
    }

    getEntity = () => {
        return ModuleModel
    }

    render() {
        return <Form
            entity={this.getEntity()}
            title={this.getTitle()}
            url={this.getEntityUrl()}
            getEntityUrl={this.getEntityUrl()}
            actionUrl={this.getEntityUrl()}
            onSuccess={this.onSuccess}
            handleClick={this.props.handleClick}
            id={this.props.match.params.id}
        />
    }
}