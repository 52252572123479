const ModuleTranslationModel = {
    id: {
        value: null,
        hidden: true
    },
    name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    short_name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    description: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
};

export default ModuleTranslationModel;