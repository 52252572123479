import React, {Component} from "react";
import {NavLink} from "react-router-dom";


class ChildrenButton extends Component {
    render() {
        if (this.props.path) {
            return <NavLink to={this.props.listApiUrl + '/' + this.props.id + this.props.path}
                            className="btn btn-success"
            >
                {this.props.name}
            </NavLink>
        }

        return ''
    }
}

export default ChildrenButton;
