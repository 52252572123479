import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import React, {Component} from "react";
import {API} from "aws-amplify";
import {ClientUser} from "../../models/ClientUser";
import Form from "../../components/Form/Form";
import {Col, FormGroup, Row} from "react-bootstrap";
import Button from "../../components/CustomButton/CustomButton";

const RESTORE_PASSWORD_URL = '/restore-password'

export default class ClientUserEdit extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.state = {
            user_id: props.match.params.id,
            courses: {},
            courses_loaded: false,
            restore_password_url: '/restore-password'
        }

        this.submitUrl = `/admin/client-users/${this.getUserId()}/courses`
        this.redirectUrl = `/admin/client-users/${this.getUserId()}`
        this.entityUrl = '/admin/client-users'
    }

    onSuccess = () => {
        this.props.handleClick(
            `Client user ${this.props.match.params.id} successfully updated`,
            "success", "tr"
        );
        this.props.history.push(this.redirectUrl || this.url);
    };

    getUserId = () => { return this.state.user_id }

    handleRestorePassword = ( event ) => {
        if (window.confirm('Are you sure?')) {
            API.post('admin', `${RESTORE_PASSWORD_URL}`, {body: {email: this.state.user.email}})
                .then(data => {
                    this.props.handleClick(this.props.name + " Successfully restored", "success", "tr");
                })
                .catch(error => {
                    this.props.handleClick(error.response.data.error || error.response.data.message, "error", "tr");
                })
        }
    };

    componentDidMount() {
        API.get(
            'admin',
            '/admin/courses'
        )
        .then(data => {
            let courses = data.items.reduce(function (obj, item) {
                obj[item.id] = `[${item.id}] ${item.translations[0].name || ''} `;
                return obj;
            }, {});
            this.setState({
                courses: courses,
                courses_loaded: true
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    render() {
        return (
            <Form
                afterEntityDataReceived={( data ) => { this.setState({user: data})}}
                entity={ClientUser(this.state.courses)}
                title={"Edit client user"}
                url={this.entityUrl}
                onSuccess={this.onSuccess}
                handleClick={this.props.handleClick}
                id={this.props.match.params.id}
                additionalButtons={[
                    <Button bsStyle="warning"
                            pullRight
                            type="button"
                            key="restorePassword"
                            onClick={(e) => this.handleRestorePassword(e)}>
                        Restore password
                    </Button>
                ]}
            />
        )
    }
}