import React, {Component} from "react";
import {API} from "aws-amplify";

const ApiConstantsContext = React.createContext();

class ApiConstantsContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            constants: {}
        }
    }

    componentDidMount() {
        API.get('admin', '/admin/constants')
            .then(data => {
                this.setState({
                    constants: data,
                })
            }).catch(error => {
            console.log("Error " + error);
        })
    }


    render() {
        return (
            <ApiConstantsContext.Provider value={this.state}>
                {this.props.children}
            </ApiConstantsContext.Provider>
        );
    }
}


export {ApiConstantsContextProvider, ApiConstantsContext};


