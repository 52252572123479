const CourseEditModel = {
    id: {
        value: null,
        hidden: true
    },
    category: {
        type: 'select',
        onChangeEvent: 'handleSelect',
        selectOptions: [
            {value: 'NUTRITION', label: 'NUTRITION'},
            {value: 'FITNESS', label: 'FITNESS'},
            {value: 'WELLBEING', label: 'WELLBEING'},
            {value: 'BEAUTY', label: 'BEAUTY'}
        ],
        value: {value: "text", label: "text"},
        requestNormalizer: (data) => {
            return data.value
        },
        responseNormalizer: (data) => {
            return {value: data, label: data}
        },
    },
    content_type: {
        type: 'select',
        onChangeEvent: 'handleSelect',
        selectOptions: [
            {value: 'VIDEO', label: 'VIDEO'},
            {value: 'TEXT', label: 'TEXT'},
            {value: 'AUDIO', label: 'AUDIO'},
        ],
        value: {value: "text", label: "text"},
        requestNormalizer: (data) => {
            return data.value
        },
        responseNormalizer: (data) => {
            return {value: data, label: data}
        },
    },
    preview_link: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
        value: '',
    },
    preview_category_link: {
        type: 'input',
        validationRules: ['required',{'regex': "^https://.*"}],
        value: '',
    },
    identifier: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    branch_name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    total_duration: {
        type: 'input',
        value: 0,
        validationRules: 'required',
        dataType: "integer"
    },
    video_preview: {
        type: 'input',
        validationRules: ['required', {'regex': "^https://.*"}],
        value: '',
    },
    position: {
        type: 'input',
        value: 0,
        validationRules: 'required',
        dataType: "integer"
    },
    test_name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    published: {
        inputType: 'checkbox',
        value: false,
        requestNormalizer: (data) => {
            return data === "true" || data === true
        }
    },
    is_public: {
        inputType: 'checkbox',
        value: '',
        requestNormalizer: (data) => {
            return data === "true" || data === true
        },
        responseNormalizer: (data) => {
            return data === "true" || data === true
        },
    },
    is_main: {
        inputType: 'checkbox',
        value: '',
        requestNormalizer: (data) => {
            return data === "true" || data === true
        },
        responseNormalizer: (data) => {
            return data === "true" || data === true
        },
    },
};

export default CourseEditModel;