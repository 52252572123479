import React, {Component} from "react";
import Form from "../../components/Form/Form";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";


export default class BaseAddEditComponent extends Component {
    static contextType = ApiConstantsContext;

    constructor() {
        super();
        this.name = ""
        this.url = ""
        this.redirectUrl = undefined
        this.submitUrl = undefined
    }

    onSuccess = () => {
        this.props.handleClick(
            this.isEdit() ?
                `${this.name} ${this.props.match.params.id} successfully updated` :
                `${this.name} successfully created`,
            "success", "tr"
        );
        this.props.history.push(this.redirectUrl || this.url);
    };

    onDataLoad = (data) => {}

    getEntity = () => {
        throw new TypeError(this.constructor.name + " must implement getEntity");
    }

    isEdit = () => {
        return Boolean(this.props.match.params.id)
    }

    getTitle = () => {
        return this.isEdit() ? `Edit ${this.name}` : `Add ${this.name}`
    }

    render() {
        return <Form
            entity={this.getEntity()}
            title={this.getTitle()}
            url={this.submitUrl || this.url}
            onSuccess={this.onSuccess}
            onDataLoad={this.onDataLoad}
            handleClick={this.props.handleClick}
            id={this.props.match.params.id}
        />
    }
}