const LectureTranslationModel = {
    id: {
        value: null,
        hidden: true
    },
    name: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    short_description: {
        type: 'input',
        validationRules: 'required',
        value: '',
    },
    description: {
        type: 'html_editor',
        value: '',
        validationRules: 'required',
        onChangeEvent: 'handleHTMLEditor'
    },
};

export default LectureTranslationModel;