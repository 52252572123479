import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import ReactPaginate from 'react-paginate';
import Loader from "../Loader/Loader";
import FilterForm from "../Form/FilterForm";
import EditButton from "./EditButton";
import AddButton from "./AddButton";
import API from '@aws-amplify/api';
import DeleteButton from "./DeleteButton";
import ChildrenButton from "./ChildrenButton";
import SyncButton from "./SyncButton";


class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination_meta: {
                page: 1,
                per_page: 20,
                total_pages: 0,
                total_items: 0
            },
            filterParams: {},
            isLoading: true
        };
    }

    componentDidMount() {
        this.getList(this.getAllQueryParams())
    }

    getList = params => {
        API.get('admin', this.props.listApiUrl, {'queryStringParameters': params}).then(data => {
            this.setState({
                data: data.items,
                isLoading: false,
                pagination_meta: data._meta
            });
        }).catch(error => {
            console.log(error);
        });
    };

    handlePageClick = page => {
        this.chooseNewPage(page['selected'] + 1);
    };

    handleFilterSubmit = data => {
        this.setState({filterParams: data}, () => {
            this.reloadData();
        });
    };
    chooseNewPage = page => {
        let new_pagination_meta = this.state.pagination_meta;
        new_pagination_meta['page'] = page;

        this.setState({pagination_meta: new_pagination_meta, isLoading: true}, () => {
            this.reloadData();
        });
    };

    reloadData = () => {
        this.setState({isLoading: true}, () => {
            this.getList(this.getAllQueryParams());
        });
    };

    getAllQueryParams = () => {
        return Object.assign({}, {
            page: this.state.pagination_meta.page,
            per_page: this.state.pagination_meta.per_page
        }, this.state.filterParams);
    };

    handleRemove = (id, event) => {
        if (window.confirm('Are you sure?')) {
            API.del('admin', this.props.removeUrl + '/' + id)
                .then(data => {
                    this.props.handleClick(this.props.name + " successfully removed", "success", "tr");
                    this.chooseNewPage(1)
                }).catch(error => {
                    this.props.handleClick(error.response.data.error || error.response.data.message, "error", "tr");
                })
        }
    };

    render() {
        var filter;

        if (this.props.filterObject !== undefined) {
            filter = <FilterForm
                object={this.props.filterObject}
                onSubmit={this.handleFilterSubmit}
            />
        }

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={this.props.name + " List"}
                                content={
                                    <div>
                                        <AddButton addPath={this.props.addPath}/>
                                        {filter}
                                        <SyncButton syncPath={this.props.syncPath}/>
                                    </div>
                                }
                            />
                            <Card
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Loader isLoading={this.state.isLoading}>
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                {this.props.model.map((prop, key) => {
                                                    return <th key={key}>{prop.col}</th>;
                                                })}
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.data.map((data, index) => {
                                                    return <tr key={index}>
                                                        {this.props.model.map((prop, index) => {
                                                            return <td key={index}>
                                                                {prop.normalizer === undefined
                                                                    ? data[prop.row] || '-'
                                                                    : prop.normalizer(data)
                                                                }
                                                            </td>;
                                                        })}
                                                        <td className="table-actions">
                                                            <div
                                                                className="btn-toolbar">
                                                                <ChildrenButton
                                                                    name={this.props.childrenName}
                                                                    listApiUrl={this.props.listApiUrl}
                                                                    path={this.props.childrenPath}
                                                                    id={data.id}/>
                                                                <EditButton
                                                                    editPath={this.props.editPath}
                                                                    id={data.id}/>
                                                                <DeleteButton
                                                                    id={data.id}
                                                                    handleRemove={this.handleRemove}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                        <Row>
                                            <Col md={12}>
                                                <ReactPaginate
                                                    previousLabel={'previous'}
                                                    nextLabel={'next'}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={this.state.pagination_meta.total_pages}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'pagination'}
                                                    forcePage={this.state.pagination_meta.page - 1}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                />
                                            </Col>
                                        </Row>
                                    </Loader>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

export default List;
