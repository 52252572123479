import React, {Component} from "react";
import List from "../../components/List/List";
import {contentfulLink} from "variables/Variables";

class ContentfulLandings extends Component {
    constructor() {
        super();
        this.syncPath = contentfulLink.admin_sync_contentful_data;
    }

    render() {
        return (
            <List
                name='Contentful'
                syncPath={this.syncPath}
                editPath=''
                removeUrl='/admin/client-user'
                listApiUrl='/admin/contentful/landings'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Unique id', row: 'unique_id'}
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
            >
            </List>

        );
    }
}

export default ContentfulLandings;
