import React, {Component} from "react";
import {Col, Grid, Row} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import SimpleReactValidator from 'simple-react-validator';
import Loader from "../Loader/Loader";
import FormRow from "./FormRow";
import {API} from 'aws-amplify';
import {Prompt, withRouter} from 'react-router'


class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            shouldBlockNavigation: true,
            onLeaveMessage: 'All unsaved changes will be lost. Are you sure?',
            responseData: {},
            resourceData: {}
        }
        this.validator = new SimpleReactValidator({className: 'text-danger'});
    }

    getDefaultValues = () => {
        let entityDefaults = {};
        Object.keys(this.props.entity).map((key) => {
            return entityDefaults[key] = this.props.entity[key].value
        });
        // Response maps against {key: label} dict which may not exist
        // at this moment (still being fetched over the Internet)
        if (Object.keys(this.state.responseData).length !== 0) {
            this.mapData('responseNormalizer', entityDefaults, this.state.responseData, this.props.entity)
        }
        Object.assign(entityDefaults, this.state.resourceData)
        return entityDefaults
    }

    prepareRequestData = () => {
        let requestData = {};
        let formValues = this.getDefaultValues();

        Object.keys(this.props.entity).map((key) => {
            if (this.props.entity[key].dataType) {
                switch (this.props.entity[key].dataType) {
                    case "integer":
                        formValues[key] = parseInt(formValues[key])
                }
            }
        });

        this.mapData('requestNormalizer', requestData, formValues, this.props.entity)
        return requestData
    };

    handleSubmit = event => {
        this.setState({shouldBlockNavigation: false})

        if (this.validator.allValid()) {
            event.preventDefault();
            let requestData = {'body': this.prepareRequestData()};
            let actionUrl = this.props.actionUrl || (this.props.url + (this.props.id ? "/" + this.props.id : ''));
            let resultPromise = this.props.id
                ? API.put('admin', actionUrl, requestData)
                : API.post('admin', actionUrl, requestData);

            resultPromise.then(data => {
                if (this.props.onSuccess) {
                    event.preventDefault();
                    this.props.onSuccess()
                }
            }).catch(error => {
                this.props.handleClick(error.response.data.error || error.response.data.message, "error", "tr");
            });
        } else {
            this.validator.showMessages();
            event.preventDefault();
            this.forceUpdate();
        }
    };

    handleDeleteSubmit = event => {
        this.setState({shouldBlockNavigation: false})

        event.preventDefault();
        let requestData = {'body': this.prepareRequestData()};
        let actionUrl = this.props.actionUrl || (this.props.url + (this.props.id ? "/" + this.props.id : ''));
        let resultPromise = API.del('admin', actionUrl, requestData);

        resultPromise.then(data => {
            if (this.props.onSuccess) {
                this.props.onSuccess()
                this.props.history.push('/admin/dashboard');
            }
        }).catch(error => {
            this.props.handleClick(error.response?.data.error || error.response.data.message, "error", "tr");
        });
    };

    handleInput = event => {
        const {target: {name, value}} = event
        this.setState(prevState => {
            let resourceData = Object.assign({}, prevState.resourceData);
            resourceData[name] = value;
            return {resourceData};
        })
    };
    handleSelect = (value, event) => {
        this.setState(prevState => {
            let resourceData = Object.assign({}, prevState.resourceData);
            resourceData[event.name] = value;
            return {resourceData};
        })
        // this.setState({[event.name]: value});
    };

    handleCollection = (_State, name) => {
        this.setState(prevState => {
            let resourceData = Object.assign({}, prevState.resourceData);
            resourceData[name] = _State;
            return {resourceData};
        })
        // this.setState({[name]: _State});
    };

    handleHTMLEditor = (name, value) => {
        this.setState(prevState => {
            let resourceData = Object.assign({}, prevState.resourceData);
            resourceData[name] = value;
            return {resourceData};
        })
    };
    mapData = (normalizer, result, data, object) => {
        Object.keys(object).map((key) => {
            let currentElement = data[key];
            if (object[key].prototype !== undefined) {
                let childs = currentElement
                let proto = object[key].prototype

                currentElement = Object.keys(childs).map((key) => {
                    let child = {}
                    this.mapData(normalizer, child, childs[key], proto)
                    return child
                })
                // return result[key] = res;
            }
            return result[key] = object[key][normalizer] === undefined
                ? currentElement
                : object[key][normalizer](currentElement);
        });
    }

    componentDidUpdate = () => {
        if (this.state.shouldBlockNavigation) {
            window.onbeforeunload = () => {
                return this.state.onLeaveMessage;
            }
        } else {
            window.onbeforeunload = undefined
        }
    }

    componentDidMount() {
        if (this.props.id) {
            API.get('admin', this.props.getEntityUrl || this.props.url + "/" + this.props.id)
                .then(data => {
                    this.setState({responseData: data, isLoading: false})

                    if (typeof this.props.afterEntityDataReceived === 'function') {
                        this.props.afterEntityDataReceived(data)
                    }
                });
        } else {
            this.setState({isLoading: false})
        }
    }

    render() {
        let defaults = this.getDefaultValues();

        return (
            <div className="content">
                <Prompt
                    message={this.state.onLeaveMessage}
                    when={this.state.shouldBlockNavigation}
                />
                <Grid fluid>
                    <Row>
                        <Col md={this.props.md || 12}>
                            <Card
                                title={this.props.title}
                                content={
                                    <Loader isLoading={this.state.isLoading}>
                                        <form onSubmit={this.handleSubmit}>
                                            {Object.keys(this.props.entity).map((key, index) => {
                                                if (!this.props.entity[key].hidden) {
                                                    return <FormRow
                                                        key={index}
                                                        name={key}
                                                        type={this.props.entity[key].type || 'input'}
                                                        inputType={this.props.entity[key].inputType}
                                                        value={defaults[key]}
                                                        validationRules={this.props.entity[key].validationRules || ''}
                                                        onChangeEvent={this[this.props.entity[key].onChangeEvent] || this.handleInput}
                                                        selectOptions={this.props.entity[key].selectOptions || null}
                                                        prototype={this.props.entity[key].prototype || null}
                                                        validator={this.validator}
                                                        isDisabled={this.props.entity[key].isDisabled || false}
                                                        md={this.props.entity[key].md || null}
                                                        placeholder={this.props.entity[key].placeholder || null}
                                                    />
                                                }

                                                return null
                                            })}
                                            <Row>
                                                <Col md={11}>
                                                    <Button bsStyle="primary"
                                                            type="submit"
                                                            key="submit"
                                                            pullLeft
                                                            onClick={(e) => this.handleSubmit(e)}>
                                                        Save
                                                    </Button>
                                                    {
                                                        this.props.hasOwnProperty('additionalButtons')
                                                            ? this.props.additionalButtons
                                                            : ''
                                                    }
                                                </Col>
                                                <Col md={1}>
                                                    <Button bsStyle="danger"
                                                            type="submit"
                                                            key="submit"
                                                            pullLeft
                                                            onClick={(e) => this.handleDeleteSubmit(e)}>
                                                        Delete
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <div className="clearfix"/>
                                        </form>
                                    </Loader>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default withRouter(Form);
