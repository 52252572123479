import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import CourseModel from "../../models/Course";
import {API} from "aws-amplify";
import React from "react";


export default class CourseAdd extends BaseAddEditComponent {
    constructor() {
        super();
        this.name = "Course"
        this.url = "/admin/courses"
        this.redirectUrl = '/admin/courses'
        this.state = {
            lectures: {},
        }
    }

    getLectures = async () => {
        const data = await API.get('admin', '/admin/lecturers')
        const response_data = {...data?.items}
        return {
               lecture_id: {
                   type: 'select',
                   validationRules: 'required',
                   onChangeEvent: 'handleSelect',
                   selectOptions: Object.keys(response_data).map(function (key) {
                       return {value: response_data[key].id, label: response_data[key].translations[0].name}
                   }),
                   requestNormalizer: (data) => {
                       return data.value
                   }
               },
           }
    }

    componentDidMount() {
        this.getLectures().then((lectures_list) => {
            this.setState({
                lectures: lectures_list,
            })
        })
    }

    getEntity = () => {
        return {...CourseModel, ...this.state.lectures};
    }
}