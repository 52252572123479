const TranslationModel = {
    id: {
        value: null,
        hidden: true
    },
    name: {
        type: 'input',
        validationRules: 'required',
        value: '',
        normalizer: (data) => {console.log(1);return '1'}
    },
    video_link: {
        type: 'input',
        validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
        value: '',
    },
    desktop_video_link: {
        type: 'input',
        validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
        value: '',
    },
    video_preview: {
        type: 'input',
        validationRules: [{'regex': "^https://.*"}],
        value: '',
    },
    video_subtitle: {
        type: 'input',
        value: '',
    },
    description: {
        type: 'html_editor',
        value: '',
        onChangeEvent: 'handleHTMLEditor'
    },
    pdf_name: {
        type: 'input',
        value: '',
    },
    pdf_link: {
        type: 'input',
        validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
        value: '',
    },
    quiz_id: {
        type: 'input',
        value: '',
    },
    quiz_src: {
        type: 'input',
        value: '',
      },
};

export default TranslationModel;